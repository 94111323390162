<template>
  <fragment>
    <div
      class="auth-wrapper auth-v1"
      style="background-color: #f4f5fa;"
    >
      <div class="auth-inner">
        <v-card class="auth-card">
          <!-- logo -->
          <v-card-title class="d-flex align-center justify-center py-7">
            <router-link
              to="/"
              class="d-flex align-center"
            >
              <v-img
                :src="`${url_public}${configDataGeneral.isotipo ? configDataGeneral.isotipo : configDataGeneral.logo}`"
                alt="logo"
                contain
                class="me-3 logoSize"
              ></v-img>

              <h2 class="text-2xl font-weight-semibold">
                {{ configDataGeneral.name }}
              </h2>
            </router-link>
          </v-card-title>

          <!-- title -->
          <v-card-text>
            <p class="text-2xl font-weight-semibold text--primary mb-2">
              {{ $t('login.title') }}
            </p>
            <p class="mb-2">
              {{ $t('login.subTitle') }}
            </p>
          </v-card-text>

          <!-- login form -->
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="model.email"
                autofocus
                outlined
                :label="$t('lbl.email')"
                placeholder="email@gmail.com"
                hide-details
                class="mb-3"
              ></v-text-field>

              <v-text-field
                v-model="model.password"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                :label="$t('lbl.password')"
                placeholder="············"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                hide-details
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>

              <div class="d-flex align-center justify-space-between flex-wrap">
                <v-checkbox
                  :label="$t('login.remember')"
                  hide-details
                  class="me-3 mt-1"
                >
                </v-checkbox>

                <!-- forgot link -->
                <router-link
                  :to="{ name: 'auth-forgot-password' }"
                  class="mt-1"
                >
                  {{ $t('login.forgot') }}
                </router-link>
              </div>

              <v-btn
                block
                color="primary"
                class="mt-6"
                :loading="loading"
                @click="login()"
              >
                {{ $t('btn.send') }}
              </v-btn>
            </v-form>
          </v-card-text>

          <!-- create new account  -->
          <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
            <span class="me-2">
              {{ $t('login.newAccount') }}
            </span>
            <router-link :to="{ name: 'auth-register' }">
              {{ $t('login.createAccount') }}
            </router-link>
          </v-card-text>

        <!-- divider
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text>-->

        <!-- social links
        <v-card-actions class="d-flex justify-center">
          <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-1"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions>-->
        </v-card>
      </div>

      <!-- background triangle shape  -->
      <img
        class="auth-mask-bg"
        height="173"
        :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
      />

      <WstIzq
        v-if="name_project === 'wwwww'"
        class="auth-tree"
        width="230"
        height="200"
        :color="$vuetify.theme.themes.light.primary"
      />
      <WstDerecha
        v-if="name_project === 'wwwww'"
        class="auth-tree-3"
        width="250"
        height="150"
        :color="$vuetify.theme.themes.light.primary"
      />

      <ProIzq
        v-if="name_project === 'ppppp'"
        class="auth-tree"
        width="230"
        height="200"
        :color="$vuetify.theme.themes.light.primary"
      />
      <ProDerecha
        v-if="name_project === 'ppppp'"
        class="auth-tree-3"
        width="250"
        height="150"
        :color="$vuetify.theme.themes.light.primary"
      />

      <KroIzq
        v-if="name_project === 'kkkkk'"
        class="auth-tree"
        width="230"
        height="160"
        :color="$vuetify.theme.themes.light.primary"
      />
      <KroDerecha
        v-if="name_project === 'kkkkk'"
        class="auth-tree-3"
        width="250"
        height="180"
        :color="$vuetify.theme.themes.light.primary"
      />

      <TravelzunIzq
        v-if="name_project === 'ttttt'"
        class="auth-tree"
        width="230"
        height="160"
        :color="$vuetify.theme.themes.light.primary"
      />
      <TravelzunDerecha
        v-if="name_project === 'ttttt'"
        class="auth-tree-3"
        width="250"
        height="180"
        :color="$vuetify.theme.themes.light.primary"
      />
    </div>

    <!--<Loading
      v-if="isLoading"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />-->
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import themeConfig from '@themeConfig'
// eslint-disable-next-line no-unused-vars, import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'

// eslint-disable-next-line import/no-unresolved
import ProDerecha from '@/views/utils/images/pro_derecha.vue'
// eslint-disable-next-line import/no-unresolved
import ProIzq from '@/views/utils/images/pro_izq.vue'
// eslint-disable-next-line import/no-unresolved
import KroDerecha from '@/views/utils/images/kro_derecha.vue'
// eslint-disable-next-line import/no-unresolved
import KroIzq from '@/views/utils/images/kro_izq.vue'
// eslint-disable-next-line import/no-unresolved
import WstIzq from '@/views/utils/images/wst_izq.vue'
// eslint-disable-next-line import/no-unresolved
import WstDerecha from '@/views/utils/images/wst_derecha.vue'
// eslint-disable-next-line import/no-unresolved
import TravelzunIzq from '@/views/utils/images/travelzun_izq.vue'
// eslint-disable-next-line import/no-unresolved
import TravelzunDerecha from '@/views/utils/images/travelzun_derecha.vue'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      email,
      password,
      socialLink,

      // themeConfig
      appLogo: themeConfig.app.logo,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },

  components: {
    ProDerecha,
    ProIzq,
    KroDerecha,
    KroIzq,
    WstIzq,
    WstDerecha,
    TravelzunIzq,
    TravelzunDerecha,
    // eslint-disable-next-line vue/no-unused-components
    Loading,
  },

  data() {
    return {
      isLoading: true,
      url_public: process.env.VUE_APP_API_URL_PLUBLIC,
      name_project: process.env.VUE_APP_PROJ_NAME,
      model: {
        email: null,
        password: null,
      },
      loading: false,
    }
  },
  computed: {
    ...mapState({
      configDataGeneral: state => state.app.configDataGeneral,
    }),
  },
  created() {
    const navegador = navigator.userAgent
    localStorage.setItem('navegador', navegador)

    /* const link = document.createElement('a')
    link.href = '#'
    setTimeout(() => {
      link.click()
    }, 100) */

    /* if (localStorage.getItem('configData')) {
      this.config = JSON.parse(localStorage.getItem('configData'))
    } */
  },
  methods: {
    ...mapMutations([
      'setPermisosAuth',
    ]),
    login() {
      // eslint-disable-next-line no-empty
      if (this.model.email && this.model.password) {
        this.loading = true
        // eslint-disable-next-line no-undef
        /* this.axios
          .post('login', this.model)
          .then(response => {
            if (response.data.success === false) {
              if (response.data.data.status === 404 || response.data.data.status === 400) {
                this.$toast.error(this.$t('msg.invalidCredentials'))
              } else if (response.data.data.status === 401) {
                this.$toast.error(this.$t('msg.userInactive'))
              }
            } else if (response.data.data.api_token) {
              // UPDATE DATA
              if (response.data.data.currency_code) {
                localStorage.setItem('currency_code', response.data.data.currency_code)
              }
              if (response.data.data.currency_symbol) {
                localStorage.setItem('currency_symbol', response.data.data.currency_symbol)
              }
              if (response.data.data.leng) {
                localStorage.setItem('lenguaje', response.data.data.leng)
              }

              localStorage.setItem('sanctum_auth', response.data.data.api_token)
              if (response.data.data.nivel === -1) {
                if (response.data.data.id_father === null) {
                  const permisos = response.data.data.perfil_afiliado.permisos.abilities
                  permisos.push('exportation:list')
                  localStorage.setItem('permisos_auth', permisos)
                } else {
                  const permisos = response.data.data.permisos.abilities
                  permisos.push('exportation:list')
                  localStorage.setItem('permisos_auth', permisos)
                }

                if (response.data.data.tableros.length > 0) {
                  localStorage.setItem('perfil', response.data.data.tableros[0].slug)
                }
              } else if (response.data.data.nivel === 0) {
                localStorage.setItem('permisos_auth', response.data.data.perfil_afiliado.permisos.abilities)

                if (response.data.data.tableros.length > 0) {
                  localStorage.setItem('perfil', response.data.data.tableros[0].slug)
                }
              } else if (response.data.data.nivel === 1) {
                if (response.data.data.id_father === null) {
                  localStorage.setItem('permisos_auth', response.data.data.perfiles[0].permisos.abilities)
                } else {
                  localStorage.setItem('permisos_auth', response.data.data.permisos.abilities)
                }

                if (response.data.data.tableros.length > 0) {
                  localStorage.setItem('perfil', response.data.data.tableros[0].slug)
                }
              } else if (response.data.data.nivel === 2) {
                if (response.data.data.id_father === null) {
                  localStorage.setItem('permisos_auth', response.data.data.agente.perfil.permisos.abilities)
                } else {
                  localStorage.setItem('permisos_auth', response.data.data.permisos.abilities)
                }

                localStorage.setItem('perfil', 'agente')
              }

              this.$router.push({ name: 'dashboard', params: { perfil: localStorage.getItem('perfil') } })

              setTimeout(() => {
                window.location.reload()
              }, 1000)
            }
          }) */

        this.axios
          .post('login', this.model)
          .then(response => {
            if (response.data.success === false) {
              if (response.data.data.status === 404 || response.data.data.status === 400) {
                this.$toast.error(this.$t('msg.invalidCredentials'))
              } else if (response.data.data.status === 401) {
                this.$toast.error(this.$t('msg.userInactive'))
              }
            } else if (response.data.data.api_token) {
              localStorage.removeItem('permisos_auth')
              localStorage.removeItem('cards_auth')

              // UPDATE DATA
              if (response.data.data.currency_code) {
                localStorage.setItem('currency_code', response.data.data.currency_code)
              }
              if (response.data.data.currency_symbol) {
                localStorage.setItem('currency_symbol', response.data.data.currency_symbol)
              }
              if (response.data.data.leng) {
                localStorage.setItem('lenguaje', response.data.data.leng)
              }

              localStorage.setItem('sanctum_auth', response.data.data.api_token)
              const permisos = []
              response.data.data.permisos.abilities.forEach(element => {
                permisos.push(element)
              })
              if (response.data.data.tableros.length > 0) {
                localStorage.setItem('perfil', response.data.data.tableros[0].slug)
              }

              if (response.data.data.nivel === -1) {
                permisos.push('exportation:list')
              } else if (response.data.data.nivel === 2) {
                localStorage.setItem('perfil', 'agente')
              }

              const jsonProfile = {
                id: response.data.data.id,
                name: response.data.data.name,
                apellidos: response.data.data.apellidos,
                email: response.data.data.email,
                company_id: response.data.data.company_id,
                company: response.data.data.company,
                agente: response.data.data.agente,
                agente_id: response.data.data.agente_id,
                active: response.data.data.active,
                avatar: response.data.data.avatar,
                role_id: response.data.data.role_id,
                nivel: response.data.data.nivel,
                permisos,
              }

              localStorage.setItem('profile', JSON.stringify(jsonProfile))

              this.setPermisosAuth(permisos)
              localStorage.setItem('permisos_auth', JSON.stringify(permisos))
              localStorage.setItem('tableros_auth', JSON.stringify(response.data.data.tableros))
              localStorage.setItem('cards_auth', JSON.stringify(response.data.data.cards))
              localStorage.setItem('user_id', response.data.data.id)

              this.$router.push({ name: 'dashboard', params: { perfil: localStorage.getItem('perfil') } })

              /* setTimeout(() => {
                window.location.reload()
              }, 1000) */
            }
          })
          .catch(error => console.log(error))

          // eslint-disable-next-line no-return-assign
          .finally(() => (this.loading = false))
      } else {
        this.$toast.error(this.$t('msg.dataRequiere'))
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';

.logoSize {
  height: 100px;
  width: 100px;
}
</style>
